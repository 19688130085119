import Swiper from 'swiper/js/swiper.esm.bundle';
import 'swiper/css/swiper.css';

$(document).ready(function () {
    let _window = $(window);

    _window.on('load scroll resize', () => {
        let scrollTop = _window.scrollTop();

        if (screen.width > 768) {
            if (scrollTop > 0) {
                $('.hide-items').hide();
                $('.show-items').show();
            } else {
                $('.hide-items').show();
                $('.show-items').hide();
            }
        }
    });

    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        },
    });

    initMobileMenu();
    initSwiper();
    initTabSection();

})
window.captchaCallback = function (response) {
    $(".g-recaptcha").val(response);
};

function initMobileMenu() {
    if ($("#mobileMenuNav").length > 0) {
        var menu = new MmenuLight($("#mobileMenuNav").get(0));

        /*mmenu.create("(max-width: 1200px)");

         mmenu.init("current");*/
        var navigator = menu.navigation({
            // selectedClass: 'Selected',
            // slidingSubmenus: true,
            theme: 'light',
            // title: 'Menu'
        });

        var drawer = menu.offcanvas({
            position: 'right'
        });

        $('#btnMobileMenu').on("click", function (ev) {
            $('#mobileMenuNav').css("display", "block");
            drawer.open();

            ev.preventDefault();
            ev.stopPropagation();
        });
    }
}

function initSwiper() {
    if ($('.faq-swiper').length > 0) {
        var faq = new Swiper('.faq-swiper', {
            direction: 'vertical',
            slidesPerView: 1,
            spaceBetween: 30,
            centeredSlides: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            speed: 1000,
            autoplay: {
              delay: 6000,
              disableOnInteraction: true
            },
            loop:true,
        });
    }

    if ($('.homepage-banner-slider').length > 0) {
        var banner = new Swiper('.homepage-banner-slider', {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 1000,
            autoplay: {
              delay: 6000,
              disableOnInteraction: true
            },
            loop:true,
            navigation: {
                nextEl: '.blue-bg .homepage-banner-nav .next-slide',
                prevEl: '.blue-bg .homepage-banner-nav .prev-slide',
            },
        });
    }
}

function initTabSection() {
    if ($('.tab-section').length > 0) {
        $('.tab-section .card').on('click', function () {
            var title = $(this).find('.title').html();
            var description = $(this).find('.hidden-description').html();
            var link = $(this).find('.hidden-link').html();

            var main_title = $('#main-title');
            var main_description = $('#main-description');
            var read_more = $('#read-more');

            if (main_title) {
                if (title) {
                    main_title.html(title);
                } else {
                    main_title.html('No title found.');
                }
            }

            if (main_description) {
                if (description) {
                    main_description.html(description);
                } else {
                    main_description.html('No description found.');
                }
            }

            if (read_more) {
                if (link) {
                    read_more.attr('href', link)
                } else {
                    read_more.attr('href', '#')
                }
            }

            $('.tab-section .card').removeClass('active');
            $(this).addClass('active');
        });
    }
}

$("input[type=tel], input[inputmode=numeric]").on('input', function (e) {
  $(this).val($(this).val().replace(/[^0-9]/g, ''));
});